<div class="table_footer">
  <div class="col-sm-12 col-md-5">
    <div class="dataTables_info">
      Showing {{ serialNumberArray[0] }} to
      {{ serialNumberArray[serialNumberArray.length - 1] }} of
      {{ totalData }} entries
    </div>
  </div>

  <div class="col-sm-12 col-md-7">
    <div class="pagination_section">
      <ul class="pagination">
        <li
          class="page-item"
          [ngClass]="{
            disabled: currentPage === 1
          }"
        >
          <a
            (click)="getMoreData('previous')"
            class="page-link"
            href="javascript:void(0);"
            tabindex="-1"
            >Previous</a
          >
        </li>

        <ng-container *ngFor="let item of pageNumberArray; let i = index">
          <li
            class="page-item"
            [class.active]="item === currentPage"
            [ngClass]="
              (pageNumberArray[currentPage - 2] > item &&
                item !== 1 &&
                pageNumberArray.length > 6) ||
              (pageNumberArray[currentPage] < item &&
                item !== 1 &&
                pageNumberArray.length > 6 &&
                pageNumberArray.length !== item)
                ? 'hide-sub-menu'
                : 'show-sub-menu'
            "
          >
            <a
              (click)="moveToPage(item)"
              class="page-link"
              href="javascript:void(0);"
            >
              {{ item }}
              <span *ngIf="item === currentPage" class="visually-hidden">(current)</span>
            </a>
          </li>
          <li
            class="page-item"
            *ngIf="i === 0 && pageNumberArray.length > 6 && currentPage > 2"
            [hidden]="currentPage === 1"
           
          >
            <a class="page-link"  (click)="moveToPage(currentPage - 2)" href="javascript:void(0);"> ... </a>
          </li>
          <li
            *ngIf="
              i === pageNumberArray.length - 2 && pageNumberArray.length > 6
            "
            [hidden]="
              currentPage >= pageNumberArray[pageNumberArray.length - 2] ||
              totalData < serialNumberArray[serialNumberArray.length - 1]
            "
            class="page-item"
           
          >
            <a class="page-link"  (click)="moveToPage(currentPage + 2)" href="javascript:void(0);"> ... </a>
          </li>
        </ng-container>

        <li
          class="page-item"
          [ngClass]="{
            disabled: currentPage === pageNumberArray[pageNumberArray.length - 1]
          }"
        >
          <a
            (click)="getMoreData('next')"
            class="page-link"
            href="javascript:void(0);"
            >Next
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
