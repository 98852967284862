import { NgModule } from '@angular/core';
import { BrowserModule, platformBrowser } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/sharedIndex';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AccessInterceptor } from './shared/interceptor/access-interceptor';
import { RouterLink, RouterModule, Routes } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, AppRoutingModule, SharedModule, BrowserAnimationsModule, RouterModule, RouterLink, MatDialogModule],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AccessInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }