import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { routes, SideBarService } from '../../core.index';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const BACKEND_URL_AUTH = environment.apiBaseUrl + "/auth";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public checkAuth: BehaviorSubject<string> = new BehaviorSubject<string>(
    localStorage.getItem('authenticated') || "false"
  );

  constructor(private router: Router, private sidebar: SideBarService, private http: HttpClient,) { }

  public login(token?: any, userId?: any, role?:any): void {
    this.checkAuth.next('true');
    localStorage.setItem('authenticated', 'true');
    localStorage.setItem('timeOut', Date());
    localStorage.setItem('layoutPosition', '1');
    localStorage.setItem('token', token);
    localStorage.setItem('userId', userId);
    localStorage.setItem('role', role);
  }
  public logout(): void {
    this.router.navigate([routes.login]);
    this.checkAuth.next("false");
    localStorage.clear();
    sessionStorage.clear();
  }
  public redirectDashboard() {
    this.router.navigate([routes.dashboard]);
  }

  sentOTPemailForForgetPswrd(data: any) {
    return this.http.post<{ message: string, status: boolean, data: any }>(BACKEND_URL_AUTH + '/forgot-password', data);
  }

  verifyEmail(data: any) {
    return this.http.post<{ message: string, status: boolean, data: any }>(BACKEND_URL_AUTH + '/verify-otp-email', data);
  }

  setNewPasswrd(data: any){
    return this.http.post<{ message: string, status: boolean, data: any }>(BACKEND_URL_AUTH + '/reset-password', data);
  }
}
